/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { settingsAPP } from "../../../config/environments/settings";
import { IAuthData } from "../auth/types/auth-types";
import { APP_CONSTANS } from "../../../constants/app";
import { ISheetsResponse } from "../sheets/types/laminas-type";
import { IDataSave } from "./konva-editorSlice";
import {
  IDataSaveResponse,
  IPersonalJobsResponse,
} from "../sheets/konva/konva-type";

const baseURLSheets = settingsAPP.api.personalJobs;

export const personalJobsAPI = createApi({
  reducerPath: "personalJobsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURLSheets,
    prepareHeaders: (headers) => {
      const dataUser = Cookies.get(APP_CONSTANS.AUTH_USER_DATA);
      if (dataUser != null && dataUser != undefined) {
        const user = JSON.parse(dataUser) as IAuthData;
        headers.set("authorization", `Bearer ${user.token}`);
      }
      headers.set("Content-Type", "application/json");
      headers.set("Accept", "*/*");

      return headers;
    },
  }),
  endpoints: (build) => ({
    postAddNewPersonalJob: build.mutation<any, IDataSave>({
      query: ({ uuid, name, preview, data, sheets, userId }) => ({
        url: "/trabajos",
        method: "POST",
        body: {
          uuid: uuid,
          name: name,
          preview: preview,
          data: JSON.stringify(data),
          sheets: JSON.stringify(sheets),
          user_id: userId,
        },
      }),
      transformResponse: (response: any) => response,
    }),
    postUpdateJobById: build.mutation<any, IDataSave>({
      query: ({ id, preview, data, sheets }) => ({
        url: `/trabajos/${id}`,
        method: "PATCH",
        body: {
          preview: preview,
          data: JSON.stringify(data),
          sheets: JSON.stringify(sheets),
        },
      }),
      transformResponse: (response: any) => response,
    }),
    postDeleteJobById: build.mutation<any, number>({
      query: (id) => ({
        url: `/trabajos/${id}`,
        method: "DELETE",
      }),
      transformResponse: (response: any) => response,
    }),
    getAllPersonalJobsPaginate: build.mutation<IPersonalJobsResponse, any>({
      query: ({ page = 1, size = 10 }) => {
        // url: `/trabajos?render=paginate&page=${page}${
        //   size ? `&size=${size}` : ""
        // }`,
        return {
          url: `/trabajos?render=paginate&page=${page}`,
          method: "GET",
        };
      },
      transformResponse: (response: IPersonalJobsResponse) => response,
    }),
  }),
});

export const {
  usePostAddNewPersonalJobMutation,
  useGetAllPersonalJobsPaginateMutation,
  usePostUpdateJobByIdMutation,
  usePostDeleteJobByIdMutation,
} = personalJobsAPI;
